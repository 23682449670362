<template>
  <app-form
    :submit-method="submitAppointment"
    class="appointment-form mt-4"
  >
    <v-row class="flex-column flex-md-row">
      <v-col cols="12" :md="isEditing ? 8 : 12">
        <app-alert
          v-if="hasSynchroDoctolib"
          ref="pastAppointmentAlert"
          type="warning"
          :message="synchronisationMessage"
          data-test="past-appointment-alert"
          class="mb-4"
        />
        <fieldset>
          <legend class="legend">
            Patient
          </legend>
          <validation-provider
            v-if="! isEditing"
            v-slot="{ errors }"
            rules="requiredModel"
            slim
          >
            <patient-search
              v-model="value.patient"
              :errors="errors"
              @input="setPatient"
            />
          </validation-provider>
          <appointment-patient-resume v-if="value.patient['@id']" :patient="value.patient" />
        </fieldset>

        <fieldset class="fieldset">
          <legend class="legend">
            Rendez-vous
          </legend>
          <v-row dense>
            <v-col cols="12">
              <app-select
                ref="appointmentSchedule"
                v-model="localSelectedSchedule"
                :rules="{ requiredModel: true, hasMotive: { motives: selectedScheduleMotives }}"
                prepend-icon="fa-calendar"
                :items="getVisibleSchedules"
                label="Agenda"
                item-text="name"
                item-value="@id"
                return-object
                data-test="schedule-input"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <app-autocomplete
                v-model="localSelectedMotive"
                :items="selectedScheduleMotives"
                :attach="false"
                label="Motif"
                item-text="name"
                item-value="@id"
                :rules="{ scheduleContainsRequiredMotive: { schedule }}"
                hide-details="auto"
                :disabled="isScheduleWithoutMotives"
                return-object
                data-test="motive-input"
                @change="updateDurationFromMotive"
              />
            </v-col>
            <v-col cols="auto">
              <validation-provider v-slot="{ errors }" rules="required" slim>
                <v-text-field
                  v-model="duration"
                  style="max-width:6em"
                  label="Durée"
                  type="number"
                  min="5"
                  suffix="min"
                  :error-messages="errors"
                  :hide-details="!errors.length"
                  data-test="duration-input"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4">
              <div class="d-flex">
                <v-menu
                  v-model="appointmentDateMenuVisible"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  :close-on-content-click="false"
                >
                  <template #activator="{ on, attrs }">
                    <validation-provider v-slot="{ errors }" rules="required" slim>
                      <v-text-field
                        v-model="formattedAppointmentDate"
                        label="Date"
                        prepend-icon="fa-clock"
                        v-bind="attrs"
                        readonly
                        hint="jj/mm/aaaa"
                        :error-messages="errors"
                        :hide-details="!errors.length"
                        data-test="date-input"
                        v-on="on"
                      />
                    </validation-provider>
                  </template>
                  <v-date-picker
                    v-model="date"
                    scrollable
                    first-day-of-week="1"
                    locale="fr-FR"
                    hide-details
                    data-test="date-picker"
                    @input="appointmentDateMenuVisible = false"
                  >
                    <v-btn
                      text
                      color="primary"
                      @click="appointmentDateMenuVisible = false"
                    >
                      Retour
                    </v-btn>
                    <v-btn text color="primary" />
                  </v-date-picker>
                </v-menu>
              </div>
            </v-col>
            <v-col cols="4">
              <validation-provider v-slot="{ errors }" rules="required" slim>
                <v-text-field
                  v-model="time"
                  label="Heure"
                  type="time"
                  :error-messages="errors"
                  :hide-details="!errors.length"
                  data-test="time-input"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <app-alert
            v-show="isPastAppointment"
            ref="pastAppointmentAlert"
            type="warning"
            message="Attention, votre rdv est dans le passé"
            data-test="past-appointment-alert"
            class="my-4"
          />
          <app-textarea-field
            v-model="value.note"
            label="Note"
            prepend-icon="fa-comment"
            data-test="note-field"
            rows="1"
            auto-grow
          />
        </fieldset>
      </v-col>

      <v-col
        v-if="isEditing"
        cols="12"
        md="4"
        class="status-update"
      >
        <fieldset>
          <legend class="legend">
            Statut
          </legend>
          <validation-provider
            v-slot="{ errors }"
            rules="requiredModel"
            slim
            vid="state"
          >
            <app-buttons-group
              v-model="value.state"
              :items="Object.values(APPOINTMENT_STATES)"
              :error-messages="errors"
              :show-icon="true"
              full-width
              mandatory
              direction="vertical"
            />
          </validation-provider>
        </fieldset>
      </v-col>
    </v-row>
  </app-form>
</template>

<script>
import PatientSearch from './PatientSearch.vue'

import { add, differenceInMinutes } from '@/utils/functions/dates'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import { extend, ValidationProvider } from 'vee-validate'

import Appointment from '@/modules/agenda/models/events/Appointment'

import AppForm from '@/components/ui/form/AppForm.vue'
import AppointmentPatientResume from './AppointmentPatientResume.vue'
import AppAlert from '@/components/ui/alert/AppAlert.vue'
import AppSelect from '@/components/ui/form/AppSelect.vue'
import AppButtonsGroup from '@/components/ui/buttonsGroup/AppButtonsGroup.vue'
import AppTextareaField from '@/components/ui/form/AppTextareaField.vue'
import AppAutocomplete from '@/components/ui/form/AppAutocomplete.vue'

import { APPOINTMENT_STATES } from '@/modules/agenda/constants'
import Schedule from '@/modules/agenda/models/Schedule'
import Motive from '@/modules/agenda/models/Motive'

export default {
  name: 'AppointmentForm',
  components: {
    PatientSearch,
    ValidationProvider,
    AppAlert,
    AppForm,
    AppointmentPatientResume,
    AppButtonsGroup,
    AppSelect,
    AppTextareaField,
    AppAutocomplete,
  },
  props: {
    value: {
      type: Appointment,
      required: true,
    },
    validationObserver: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      birthDateMenuVisible: false,
      appointmentDateMenuVisible: false,
      APPOINTMENT_STATES,
      localSelectedSchedule: null,
      localSelectedMotive: null,
    }
  },
  computed: {
    ...mapGetters('agenda', ['getVisibleSchedules', 'getAppointmentMotives', 'getScheduleFromIri', 'getAppointmentMotiveFromIri']),
    hasSynchroDoctolib () {
      return this.schedule?.source === 'doctolib'
    },
    synchronisationMessage () {
      return this.isEditing
        ? 'Attention, seule la note et le statut seront synchronisés avec Doctolib'
        : 'Attention, votre RDV ne sera pas synchronisé avec Doctolib'
    },
    isEditing () {
      return !! this.value['@id']
    },
    date: {
      get () {
        return this.value.startDateTime.substr(0, 10)
      },
      /** Met à jour startDateTime en conservant l'horaire du rdv */
      set (newVal) {
        this.value.startDateTime = this.updateDateTime(this.value.startDateTime, newVal)
        this.value.endDateTime = this.updateDateTime(this.value.endDateTime, newVal)
      },
    },
    time: {
      get () {
        return new Date(this.value.startDateTime).toTimeString().substring(0, 5)
      },
      /**
       * Met à jour startDateTime en conservant la durée intiale du rdv
       */
      set (newVal) {
        if(! newVal) {
          return null
        }
        const startDate = new Date(this.value.startDateTime)
        const endDate = new Date(this.value.endDateTime)
        const initialDuration = differenceInMinutes(endDate, startDate)

        const [hours, minutes] = newVal.split(':')
        startDate.setHours(+ hours, + minutes)
        this.value.startDateTime = startDate.toISOString()
        this.value.endDateTime = add(startDate, { minutes: initialDuration }).toISOString()
      },
    },
    duration: {
      get () {
        return differenceInMinutes(new Date(this.value.endDateTime), new Date(this.value.startDateTime))
      },
      set (duration) {
        const startDate = new Date(this.value.startDateTime)
        this.value.endDateTime = add(startDate, { minutes: + duration }).toISOString()
      },
    },
    isPastAppointment () {
      const now = new Date()
      const eventDate = new Date(this.date + 'T' + this.time)

      return now > eventDate
    },
    formattedAppointmentDate: {
      get () {
        return new Date(this.date).toLocaleDateString('fr-FR')
      },
      set (newDate) {
        this.date = newDate
      },
    },
    selectedScheduleMotives () {
      return this.getScheduleMotives(this.schedule)
    },
    isScheduleWithoutMotives () {
      return ! this.selectedScheduleMotives.length
    },
    schedule () {
      return new Schedule(this.getScheduleFromIri(this.value.schedule))
    },
    motive () {
      return new Motive(this.getAppointmentMotiveFromIri(this.value.motive))
    },
  },
  watch: {
    'value.schedule': {
      immediate: true,
      async handler (newScheduleIri, oldScheduleIri) {
        if (! this.isEditing || newScheduleIri !== oldScheduleIri) {
          this.localSelectedSchedule = this.schedule
          const hasSameMotive = this.schedule.appointmentMotives.findIndex(item => item['@id'] === this.motive['@id']) > - 1
          // Dans le cas d'une édition, on ne souhaite pas présélectionner de motif si on change d'agenda et que le motif n'existe pas dans le nouveau
          if (! this.isEditing) {
            this.selectMotiveFromSchedule(this.schedule)
            // Mais on affiche une alerte en forçant une validation du formulaire
          } else if (! hasSameMotive) {
            await this.$nextTick()
            this.validationObserver.validate()
          }
        }
      },
    },
    'value.motive': {
      immediate: true,
      async handler (newMotiveIri, oldMotiveIri) {
        if (! this.isEditing || newMotiveIri !== oldMotiveIri) {
          this.localSelectedMotive = this.motive
        }
      },
    },
    localSelectedSchedule: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.value.schedule = newValue['@id']
      }
    },
    localSelectedMotive: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.value.motive = newValue?.['@id']
      }
    },
  },
  methods: {
    ...mapActions('agenda', ['insertAppointment', 'updateAppointment']),
    ...mapMutations('app', { setSnack: 'SET_SNACK' }),
    /**
     * Met à jour une date au format ISO en conservant le time hh:mm de celle ci
     * @param { String } isodate, date au format iso
     * @param { String } newDate, date au format 'YYYY-MM-DD'
     */
    updateDateTime (isodate, newDate) {
      const startDateTime = new Date(isodate)
      const initialHours = startDateTime.getHours()
      const initialMinutes = startDateTime.getMinutes()
      const newStartDateTime = new Date(newDate)
      newStartDateTime.setHours(initialHours)
      newStartDateTime.setMinutes(initialMinutes)
      return newStartDateTime.toISOString()
    },
    async submitAppointment () {
      this.isEditing ?
        await this.updateAppointment(this.value) :
        await this.insertAppointment(this.value)
      this.setSnack({
        message: this.isEditing ?
          'Le rendez-vous a été modifié avec succès' :
          'Le rendez-vous a été ajouté avec succès',
      })
    },
    setPatient (patient) {
      this.value.patient = patient
      this.$refs.appointmentSchedule.focus()
    },
    /**
     * Modifie la date de fin du rdv en ajoutant le nombre de minutes à la date de début
     * @param { Object } motive Le motif à partir du quel récupérer les minutes à ajouter pour mettre à jour endDateTime
     */
    updateDurationFromMotive (motive) {
      const startDateTime = new Date(this.value.startDateTime)
      this.value.endDateTime = add(startDateTime, { minutes: motive.duration }).toISOString()
    },
    getScheduleMotives (schedule) {
      return this.getAppointmentMotives.filter(motive => {
        return motive.practitionerSchedules
          .some(motiveSchedule => motiveSchedule['@id'] === schedule['@id'])
      })
    },
    /**
     * Permet d'automatiser la sélection d'un motif pour un agenda
     * Restaure également la durée en cas d'édition
     */
    selectMotiveFromSchedule (schedule) {
      const motives = this.getScheduleMotives(schedule)

      if (schedule.defaultAppointmentMotive) {
        const motiveIndex = motives.findIndex(motive => motive['@id'] === schedule.defaultAppointmentMotive['@id'])
        this.localSelectedMotive = motives[motiveIndex]
      } else {
        this.localSelectedMotive = motives.length === 1 ? motives[0] : null
      }

      if (this.localSelectedMotive) {
        this.updateDurationFromMotive(this.motive)
      }
    },
  },
}

extend('hasMotive', {
  validate (value, { motives }) {
    return motives.length || 'Aucun motif disponible pour cet agenda'
  },
  params: ['motives'],
})

extend('scheduleContainsRequiredMotive', {
  validate (value, { schedule }) {
    const hasSameMotive = schedule.appointmentMotives.findIndex(motive => motive['@id'] === value?.['@id']) > - 1
    return value && hasSameMotive || `Le motif "${value.name}" n'est pas disponible pour cet agenda et doit être redéfini`
  },
  params: ['schedule'],
})

</script>

<style lang="scss" scoped>
.section-label {
  font-weight: 600;
}

label {
  text-transform: lowercase;
}
</style>