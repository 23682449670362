<template>
  <article
    class="person-details"
    :class="{
      'person-details--large': large,
      'person-details--small': small,
      'person-details--x-small': xSmall,
      'person-details--align-content-top': alignContentTop
    }"
  >
    <div v-if="! hideAvatar" class="person-details__avatar">
      <div v-if="showInitials" v-test="'person-initials'">
        {{ initials }}
      </div>
      <app-avatar
        v-else
        size="100%"
        :gender="person.gender"
        :label="avatarLabel"
      />
    </div>

    <div v-if="! avatarOnly">
      <h1 v-test="'person-civil-state'" class="person-details__civil-state">
        <slot name="civil-state" :civil-state="person.getCivilState()">
          <span>{{ person.getCivilState() }}</span>
        </slot>
      </h1>
      <slot name="sub-content" />
    </div>
  </article>
</template>

<script>
import AppAvatar from '@/components/ui/avatar/AppAvatar.vue'

import personDetailsMixin from '@/components/ui/personDetails/personDetailsMixin'

import Person from '@/models/Person'

/**
 * Les détails d'une personne de l'application
 */
export default {
  name: 'AppPersonDetails',
  components: { AppAvatar },
  mixins: [personDetailsMixin],
  props: {
    /**
     * La personne à afficher
     */
    person: {
      type: Person,
      required: true,
    },
  },
  computed: {
    initials () {
      return `${this.person.firstNames[0]}${this.person.familyName[0]}`
    },
  },
}
</script>

<style lang="scss" scoped>
.person-details {
  display: flex;
  align-items: center;
  gap: map-get($spacers, 3);

  &--align-content-top {
    align-items: flex-start;
  }

  &--small,
  &--x-small {
    gap: map-get($spacers, 2);
  }

  &--large &__avatar {
    width: 56px;
    height: 56px;
  }

  &--small {
    .person-details__avatar {
      width: 32px;
      height: 32px;
    }
    .person-details__civil-state {
      font-size: 13px;
      font-weight: 600;
    }
  }

  &--x-small {
    .person-details__avatar {
      width: 28px;
      height: 28px;
      font-weight: 500;
      font-size: 12px;
    }
    .person-details__civil-state {
      font-size: 13px;
      font-weight: 400;
    }
  }

  &__avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--v-content-base);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
  }

  &__civil-state {
    font-size: 16px;
    font-weight: 600;
  }
}
</style>