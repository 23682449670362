import NovaTools from '@/nova-tools/NovaTools'
import axios from 'axios'
import { getFileContent } from '@/utils/functions/files'
import ConsultationDocument from '@/modules/patient/models/ConsultationDocument'
import ConsultationCertificate
  from '@/modules/patient/components/consultation/certificates/models/ConsultationCertificate'
import ConsultationDischargeLetterDocument from '@/modules/patient/models/ConsultationDischargeLetterDocument'
import ConsultationTelemedicineAct
  from '@/modules/patient/components/consultation/telemedicineActs/models/ConsultationTelemedicineAct'
import IcanopeeDocument from '@/modules/patient/models/IcanopeeDocument'
import { getFromAPI } from '@/services/api'
import PatientHealthSummaryDocument from '@/modules/patient/models/PatientHealthSummaryDocument'
const formats = ['pdf', 'text', 'jpeg', 'tiff', 'rtf', 'vsm', 'dlu', 'fludt', 'fludr', 'bio']
export class Efficience_common {

  /**
   * Permet d'ouvrir le dmp d'un patient dans une modale
   * @returns {int} l'id de la modale
   */
  openModalIframe (patient) {
    return NovaTools.icanopee.efficience_dmp.openModalIframe(patient)
  }

  /**
   * Permet d'envoyer un document au dmp d'un patient au travers d'une iframe d'efficience en mode pilotage
   */
  async sendDocumentByIframe (patient, icanopeeDocument) {
    await NovaTools.icanopee.efficience_manual.sendDocumentByIframe(patient, icanopeeDocument)
  }

  /**
   * Permet d'envoyer un document au dmp d'un patient en tache de fond
   */
  async sendAutoFromDocument (document) {
    if (document && document.dmpSync) {
      const icanopeeDocument = await NovaTools.icanopee.efficience_background.getIcanopeeDocumentFromDocument(document)
      await NovaTools.icanopee.efficience_background.sendDocumentInBackground(icanopeeDocument)
    }
  }

  async sendAutoFromVsm (document) {
    const { data } = await getFromAPI(document.patient)
    const patientIns = data.insIdentity.ins
    const contentBase64 = document.fileContentBase64 ?? await NovaTools.icanopee.efficience.getBase64Content(document.contentUrl)
    const icanopeeDocument = new IcanopeeDocument(patientIns, {
      title: document.name ?? 'Volet de synthèse médicale',
      fileContentBase64: contentBase64,
      format: 'pdf',
      typeCode: 'SYNTH',
      practice: '07',
      documentType: document.documentType,
      iri: document['@id'],
    })
    await NovaTools.icanopee.efficience_background.sendDocumentInBackground(icanopeeDocument)
  }

  getEfficienceIframePatientUrl (patientIns) {
    const params = { insNir: `${patientIns}${import.meta.env.VUE_APP_ICANOPEE_DMP_CONNECT_JS2_ENVIRONMENT}` }
    const encodedParams = NovaTools.base64.encode(decodeURIComponent(encodeURIComponent(JSON.stringify(params))))
    return `${import.meta.env.VUE_APP_ICANOPEE_EFFICIENCE_BASE_URL}/dmp/#params=${encodedParams}`
  }

  getEfficienceIframeSearchUrl (patient) {
    const params = { Ins: { insNir: `${patient.insIdentity.ins}${import.meta.env.VUE_APP_ICANOPEE_DMP_CONNECT_JS2_ENVIRONMENT}` } }
    const encodedParams = NovaTools.base64.encode(decodeURIComponent(encodeURIComponent(JSON.stringify(params))))
    return `${import.meta.env.VUE_APP_ICANOPEE_EFFICIENCE_BASE_URL}/dmp/#params=${encodedParams}`
  }

  convertToIcanopeeFormat (value) {
    if (formats.indexOf(value) > - 1) {
      return value
    }
    switch (value) {
    case 'application/pdf':
      return 'pdf'
    case 'text/plain':
      return 'text'
    case 'image/jpeg':
      return 'jpeg'
    case 'image/tiff':
      return 'tiff'
    case 'application/rtf':
      return 'rtf'
    default:
      return null
    }
  }
  async getBase64Content (contentUrl) {
    const { data } = await axios.get(contentUrl, { responseType: 'blob' })
    return await getFileContent(data)
  }

  async updateDocumentInStore (data) {
    if (data['@type'] === 'ConsultationDocument') {
      await window.store.dispatch('patient/updateDocument', new ConsultationDocument(data))
    } else if (data['@type'] === 'ConsultationCertificateDocument') {
      await window.store.dispatch('patient/updateDocument', new ConsultationCertificate(data))
    } else if (data['@type'] === 'ConsultationDischargeLetterDocument') {
      await window.store.dispatch('patient/updateDocument', new ConsultationDischargeLetterDocument(data))
    } else if (data['@type'] === 'ConsultationTelemedicineActDocument') {
      await window.store.dispatch('patient/updateDocument', new ConsultationTelemedicineAct(data))
    } else if (data['@type'] === 'PrescriptionDocument') {
      await window.store.dispatch('patient/updateDocument', data)
    } else if (data['@type'] === 'PatientHealthSummaryDocument') {
      await window.store.dispatch('patient/setIsSynchronizedDmp', new PatientHealthSummaryDocument(data))
    }
  }
}
export default new Efficience_common()