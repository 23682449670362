<template>
  <div class="patient-sidebar-panel-listing">
    <div class="patient-sidebar-panel-listing__title">
      <span v-test="'patient-sidebar-panel-listing-title'" v-tooltip="{ text: tooltip, disabled: ! tooltip }">{{ title }}</span>
      <n-button
        v-if="form && ! hideAddButton"
        v-test="'listing-add-button'"
        v-tooltip="createLabel"
        icon="add"
        size="xSmall"
        x-small
        @click="add"
      />
    </div>
    <n-list
      v-test="'listing'"
      :items="isItemsGroupables ? Object.keys(displayedItems) : displayedItems"
      :empty-text="noDataText"
      :spacing="1"
      :inset-x="4"
      :loading="loading"
      :class="{ 'mt-1': isItemsGroupables }"
      :skeleton-count="1"
      :hoverable="! isItemsGroupables && ! loading"
    >
      <template #item="listingItemProps">
        <n-expandable v-if="isItemsGroupables" :title="listingItemProps.item" :content-spacing-top="0">
          <template #title>
            <h3 class="subtitle-3">
              <slot name="category-title" :category="listingItemProps.item">
                {{ listingItemProps.item }}
              </slot>
            </h3>
          </template>
          <n-list
            v-test="'listing-category-list'"
            :spacing="1"
            hoverable
            :inset-x="4"
            class="patient-sidebar-panel-listing__category-list"
            :items="displayedItems[listingItemProps.item]"
          >
            <template #item="categoryItemProps">
              <slot name="item" :item="categoryItemProps.item" :edit="edit" />
            </template>
          </n-list>
        </n-expandable>
        <div v-else v-test="'listing-item'">
          <slot
            name="item"
            :item="listingItemProps.item"
            :edit="edit"
          />
        </div>
      </template>
      <template #skeleton>
        <app-skeleton-loader
          v-test="'listing-skeleton'"
          type="list-item-two-line@2"
          class="app-atient-sidebar-panel-listing__skeleton"
        />
      </template>
    </n-list>
    <app-modal-form
      v-if="form"
      v-model="selectedItem"
      v-test="'listing-form'"
      :title="isEditing ? editLabel : createLabel"
      :form="form"
      :width="modalWidth"
      :scrollable="scrollableModal"
      :patient="patient"
      @cancel="reset"
      @submitSuccess="handleSubmitSuccess"
    />
  </div>
</template>

<script>
import AppSkeletonLoader from '@/components/ui/loaders/AppSkeletonLoader.vue'
import AppModalForm from '@/components/ui/modal/AppModalForm.vue'

import { groupBy } from 'lodash'

import Patient from '@/modules/patient/models/Patient'

export default {
  name: 'PatientSidebarPanelListing',
  components: {
    AppModalForm,
    AppSkeletonLoader,
  },
  props: {
    /**
     * Le titre du listing
     */
    title: {
      type: String,
      default: null,
    },
    /**
     * Le texte à afficher si aucun élément n'est présent dans le listing
     */
    noDataText: {
      type: String,
      default: 'Aucune donnée à afficher',
    },
    /**
     * Les éléments à afficher
     */
    items: {
      type: Array,
      required: true,
    },
    /**
     * La label du lien de création d'un nouvel élément
     */
    createLabel: {
      type: String,
      default: 'Ajouter',
    },
    /**
     * La label du lien de création d'un nouvel élément
     */
    editLabel: {
      type: String,
      default: 'Éditer',
    },
    /**
     * Le formulaire de création de l'élément
     */
    form: {
      type: Object,
      default: null,
    },
    /**
     * Le patient associé
     */
    patient: {
      type: Patient,
      required: true,
    },
    /**
     * Indique si le listing est en train de charger
     * @default false
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Classe du modèle à appliquer automatiquement
     */
    itemModelClass: {
      type: Function,
      default: null,
    },
    /**
     * Indique s'il faut masquer le bouton d'ajout
     */
    hideAddButton: {
      type: Boolean,
      default: false,
    },
    /**
     * Permet de grouper les entité par une clé particulière
     */
    groupBy: {
      type: String,
      default: null,
    },
    /**
     * La taille de la fenêtre modale
     */
    modalWidth: {
      type: String,
      default: '600px',
    },
    /**
     * Affiche dans une tooltip sur le titre
     */
    tooltip: {
      type: String,
      default: '',
    },
    /**
     * Rend la modale scrollable ou non en fonction du type de formulaire
     */
    scrollableModal: {
      type: Boolean,
      default: false,
    },
    /**
     * Accesseur personnalisé servant de valeur passé à la modale lors de son ouverture
     */
    valueGetter: {
      type: Function,
      default: null,
    },
  },
  data () {
    return {
      isEditing: false,
      selectedItem: null,
    }
  },
  computed: {
    isItemsGroupables () {
      return this.groupBy && ! this.items.every(item => ! item[this.groupBy])
    },
    displayedItems () {
      if (this.isItemsGroupables) {
        return groupBy(this.items, this.groupBy)
      }
      return this.items
    },
  },
  methods: {
    add () {
      this.isEditing = false
      if (this.valueGetter) {
        this.selectedItem = this.valueGetter()
        return
      }
      this.selectedItem = this.itemModelClass ? new this.itemModelClass() : {}
    },
    edit (item) {
      this.isEditing = true
      if (this.valueGetter) {
        this.selectedItem = this.valueGetter(item)
        return
      }
      this.selectedItem = this.itemModelClass ? new this.itemModelClass(item) : null
    },
    reset () {
      this.selectedItem = null
    },
    handleSubmitSuccess (payload) {
      this.reset()
      this.$emit('submit-success', payload)
    },
  },
}
</script>

<style scoped lang="scss">
  .patient-sidebar-panel-listing {
    display: flex;
    flex-direction: column;
    gap: map-get($spacers, 1);
    padding: map-get($spacers, 4);

    &__title {
      display: flex;
      align-items: center;
      font-size: 12px;
      gap: map-get($spacers, 1);
      color: var(--v-secondary-base);
    }

    &__skeleton {
      display: flex;
      flex-direction: column;
      gap: 12px;

      ::v-deep {
        .v-skeleton-loader__list-item-two-line {
          padding: 0 !important;
          margin: 0 !important;
          height: auto;
        }
      }
    }

    &__category-list {
      ::v-deep {
        .n-list {
          &__item:before {
            left: -#{map-get($spacers, 4)};
            width: calc(#{'100% +' map-get($spacers, 4)});
          }
        }
      }
    }
  }
</style>