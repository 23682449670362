<template>
  <div class="dashboard-events-timeline-appointment-sub-content">
    <div class="dashboard-events-timeline-appointment-sub-content__infos">
      <app-badge :color="motive.color" data-test="appointment-motive">
        {{ motive.name }}
      </app-badge>
      <app-badge icon="address" color="secondary" data-test="appointment-activity-place">
        {{ schedule.getActivityPlace() ? schedule.getActivityPlace().name : '' }}
      </app-badge>
    </div>
    <p v-if="localAppointment.note" class="dashboard-events-timeline-appointment-sub-content__note" data-test="appointment-note">
      {{ localAppointment.note }}
    </p>
    <div class="dashboard-events-timeline-appointment-sub-content__settings">
      <appointment-waiting-room-switch
        :appointment.sync="localAppointment"
        data-test="waiting-room-switch"
        @change="isSwitched => showMenu = !isSwitched"
      />
      <v-fade-transition>
        <appointment-waiting-room-actions-menu
          v-if="showMenu"
          :appointment="localAppointment"
          class="dashboard-events-timeline-appointment__actions"
          data-test="waiting-room-actions-menu"
        />
      </v-fade-transition>
    </div>
  </div>
</template>

<script>
import AppBadge from '@/components/ui/badge/AppBadge.vue'
import AppointmentWaitingRoomSwitch from '@/modules/users/components/dashboard/events/timeline/appointment/AppointmentWaitingRoomSwitch.vue'
import AppointmentWaitingRoomActionsMenu from '@/modules/users/components/dashboard/events/timeline/appointment/AppointmentWaitingRoomActionsMenu.vue'

import Appointment from '@/modules/agenda/models/events/Appointment'

import { APPOINTMENT_STATES } from '@/modules/agenda/constants'
import Schedule from '@/modules/agenda/models/Schedule'
import Motive from '@/modules/agenda/models/Motive'
import { mapGetters } from 'vuex'

export default {
  name: 'DashboardEventsTimelineAppointmentDetails',
  components: {
    AppBadge,
    AppointmentWaitingRoomSwitch,
    AppointmentWaitingRoomActionsMenu,
  },
  props: {
    appointment: {
      type: Appointment,
      required: true,
    },
  },
  data () {
    return {
      localAppointment: new Appointment(this.appointment),
      showMenu: this.appointment.state === APPOINTMENT_STATES.CONFIRMED.value,
    }
  },
  computed: {
    ...mapGetters('agenda', ['getScheduleFromIri', 'getAppointmentMotiveFromIri']),
    schedule () {
      return new Schedule(this.getScheduleFromIri(this.localAppointment.schedule))
    },
    motive () {
      return new Motive(this.getAppointmentMotiveFromIri(this.localAppointment.motive))
    },
  },
  watch: {
    deep: true,
    appointment: {
      handler (appointment) {
        this.localAppointment = appointment
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.dashboard-events-timeline-appointment-sub-content {
  display: flex;
  flex-direction: column;
  gap: map-get($spacers, 3);

  @include media-md {
    gap: map-get($spacers, 2);
  }

  &__infos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: map-get($spacers, 2);

    @include media-md {
      flex-direction: row;
      align-items: center;
      gap: map-get($spacers, 4);
    }
  }

  &__settings {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: map-get($spacers, 1);

    @include media-md {
      flex-direction: row;
      align-items: center;
      gap: map-get($spacers, 4);
    }
  }

  &__note {
    font-size: 13px;
    color: var(--v-secondary-base);
    font-style: italic;
  }
}
</style>