<template>
  <div>
    <app-modal
      scrollable
      :is-open="isOpen"
      title="Générer un VSM"
      width="60%"
      @update:isOpen="isOpen => $emit('update:is-open', isOpen)"
    >
      <div class="health-summary-preview-modal-form__pdf-preview">
        <health-summary-preview
          v-if="isOpen"
          class="health-summary-preview-modal-form__pdf-preview__preview"
          :patient-iri="patient.getIri()"
          :patient-ins="patient.insIdentity"
          :dmp-sync.sync="dmpSync"
          @add-vigilance-points="newVigilancePoints => vigilancePoints = newVigilancePoints"
        />
      </div>
      <template #actions="{ cancel }">
        <div class="health-summary-preview-modal-form__actions">
          <div class="flex-grow-1">
            <send-by-mss-option v-model="sendDocumentBySecureMessaging" :patient="patient" />
          </div>
          <div class="flex-list-2">
            <n-button label="Annuler" @click="cancel" />
            <n-button
              :label="sendDocumentBySecureMessaging ? 'Générer et envoyer' : 'Générer'"
              bg-color="primary"
              :loading="isGeneratingDocument"
              @click="handleSubmit"
            />
          </div>
        </div>
      </template>
    </app-modal>
    <send-message-modal-form v-if="!! mssMessage" :message="mssMessage" :is-open.sync="isMessagingModalFormOpened" />
  </div>
</template>

<script>
import AppModal from '@/components/ui/modal/AppModal.vue'
import SendMessageModalForm from '@/modules/secureMessaging/modules/messaging/forms/SendMessageModalForm.vue'
import HealthSummaryPreview from '@/modules/patient/modules/patientFile/modules/documents/components/healthSummariesPanel/HealthSummaryPreview.vue'
import SendByMssOption from '@/modules/secureMessaging/modules/messaging/components/sendByMssOption/SendByMssOption.vue'

import Patient from '@/modules/patient/models/Patient'
import PatientHealthSummaryDocument from '@/modules/patient/models/PatientHealthSummaryDocument'

import { mapGetters } from 'vuex'
import { postToAPI } from '@/services/api'
import { getMssMessageForDocument } from '@/modules/secureMessaging/modules/messaging/utils/getMssMessageForDocument'
import NovaTools from '@/nova-tools/NovaTools'

export default {
  name: 'HealthSummaryPreviewModal',
  components: {
    AppModal,
    SendMessageModalForm,
    HealthSummaryPreview,
    SendByMssOption,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      mssMessage: null,
      sendDocumentBySecureMessaging: false,
      isGeneratingDocument: false,
      isMessagingModalFormOpened: false,
      vigilancePoints: null,
      dmpSync: true,
    }
  },
  computed: { ...mapGetters('cardReaders', ['getPincode']) },
  watch: {
    isOpen (isOpen) {
      if (! isOpen) {
        this.sendDocumentBySecureMessaging = false
      }
    },
  },
  methods: {
    async generateVsm () {
      const { data: patientHealthSummaryDocument } = await postToAPI('/api/patient_health_summary_documents', {
        patient: this.patient.getIri(),
        dmpUniqueId: null,
        dmpSync: this.dmpSync,
        vigilancePoints: this.vigilancePoints,
      })
      const healthSummaryDocument = new PatientHealthSummaryDocument(patientHealthSummaryDocument)
      NovaTools.notify.success('Le volet de synthèse médicale a été généré avec succès')
      this.$emit('health-summary-generated', healthSummaryDocument)
      return healthSummaryDocument
    },
    async handleSubmit () {
      this.isGeneratingDocument = true
      try {
        // On enregistre la valeur de "sendDocumentBySecureMessaging" pour éviter qu'elle soit remise à "false" dans le cas ou
        // l'event "health-summary-generated" provoquerait la modification de "isOpen" et donc le reset de "sendDocumentBySecureMessaging"
        const canOpenSendMessageModalForm = this.sendDocumentBySecureMessaging
        const patientHealthSummaryDocument = await this.generateVsm()
        if (canOpenSendMessageModalForm) {
          this.isMessagingModalFormOpened = true
          this.mssMessage = await getMssMessageForDocument(patientHealthSummaryDocument, this.patient)
        }
        await NovaTools.icanopee.efficience.sendAutoFromDocument(patientHealthSummaryDocument)
      } finally {
        this.isGeneratingDocument = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.health-summary-preview-modal-form {
  &__pdf-preview {
    height: 60vh;

    &__preview {
      height: 100%;
    }
  }
  &__actions {
    width: 100%;
    display: flex;
    align-items: center;
  }
}
</style>