<template>
  <validation-provider
    ref="allergyValidator"
    v-slot="{ errors }"
    tag="div"
  >
    <allergy-autocomplete
      v-if="! hasSelection"
      ref="allergy-autocomplete"
      v-test="'allergy-autocomplete'"
      @input="$emit('input', $event)"
    />
    <app-panel
      v-else
      v-test="'selected-allergy-panel'"
      is-outlined
      is-flat
      dense
      :title="value.label"
      :actions="actions"
    />
    <div v-if="errors.length > 0" class="drug-allergy-autocomplete__error-messages">
      <p v-for="(error, index) in errors" :key="index" class="mt-2">
        {{ error }}
      </p>
    </div>
  </validation-provider>
</template>

<script>
import AllergyAutocomplete from '@/components/lap/synapse-widgets/AllergyAutocomplete.vue'
import AppPanel from '@/components/ui/panel/AppPanel.vue'
import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem'

import SynapseAutocompleteMixin from '@/mixins/synapseAutocomplete'
import fieldMixin from '@/mixins/fields.js'

import { ValidationProvider, validate } from 'vee-validate'

export default {
  name: 'DrugAllergyAutocomplete',
  components: {
    AllergyAutocomplete,
    AppPanel,
    ValidationProvider,
  },
  mixins: [SynapseAutocompleteMixin, fieldMixin],
  props: {
    value: {
      type: Object,
      default: null,
    },
    rules: {
      type: [String, Object],
      default: null,
    },
  },
  computed: {
    hasSelection () {
      return !! this.value?.label
    },
    actions () {
      return { updateAllergy: new ActionMenuItem('edit', 'Modifier l\'allergie', () => this.$emit('input', null)) }
    },
  },
  watch: {
    value: {
      deep: true,
      async handler () {
        const validationResult = await validate(this.value?.label, this.rules)
        this.$refs['allergyValidator'].applyResult(validationResult)
      },
    },
  },
}
</script>
<style lang="scss" scoped>
.drug-allergy-autocomplete__error-messages {
  color: var(--v-error-base);
}
::v-deep {
  .panel__header {
    margin-bottom: 0 !important;
  }
  // Nouveau Design System
  .component-allergy-autocomplete-theriaque {

    fieldset {
      margin: 0 !important;

      label {
        font-family: 'Poppins' !important;
        border-left: none;
        border-right: none;
        border-top: none;
        border-radius: 0;
      }
    }

    .dropdown-menu-allergy {
      border: none;
      box-shadow: 0 4px 6px 0 rgb(32 33 36 / 28%);
      border-radius: 0 0 3px 3px;
      margin-top: 0 !important;

      button {
        padding-left: 16px;
        height: 48px;
        min-height: 48px;

        &>div>div>span,
        & > span > span {
          font-family: 'Poppins' !important;
          font-size: 13px !important;
        }

        &:hover {
          background-color: var(--v-text-lighten5);
        }
      }
    }
  }

  // TODO : à supprimer après passage au nouveau Design System
  .synapse-app {
    .input-wrapper {
      font-family: 'Poppins';
      border-left: none;
      border-right: none;
      border-top: none;
      border-radius: 0;
    }
    .options {
      border: none;
      box-shadow: 0 4px 6px 0 rgb(32 33 36 / 28%);
      overflow-y: auto;
      max-height: 300px;

      .option {
        font-family: 'Poppins';
        font-size: 13px;
      }
    }
  }
}
</style>