<template>
  <synapse-autocomplete-wrapper
    :synapse-input-widget="MedicalResourceAutocomplete"
    :value="value"
    :item-text="getDrugLabel"
    :item-value="getDrugValue"
    label="Rechercher un traitement"
    hide-label
    :rules="rules"
    :append-icon="appendIcon"
    class="drug-autocomplete"
    @input="onItemSelected"
  />
</template>

<script>
import SynapseAutocompleteWrapper from '@/components/lap/SynapseAutocompleteWrapper.vue'
import MedicalResourceAutocomplete from '@/components/lap/synapse-widgets/MedicalResourceAutocomplete.vue'

export default {
  name: 'DrugAutocomplete',
  components: { SynapseAutocompleteWrapper },
  props: {
    value: {
      type: Object,
      default: null,
    },
    appendIcon: {
      type: String,
      default: 'search',
    },
    rules: {
      type: String,
      default: null,
    },
  },
  data () {
    return { MedicalResourceAutocomplete }
  },
  methods: {
    onItemSelected (drug) {
      this.$emit('input', drug)
    },
    getDrugLabel (drug) {
      return drug.label
    },
    getDrugValue (drug) {
      return drug.uuid ?? drug.drug_id ?? drug.uri
    },
  },
}
</script>