import sentry from '@/nova-tools/traces/sentry'

export class Traces {
  initialise () {
    sentry.initialise()
  }
  addMessageError (message) {
    sentry.addMessageError(message)
  }
}

export default new Traces()