import Vue from 'vue'
import * as _sentry from '@sentry/vue'
import { getEnv } from '@/utils/functions/env.js'

export class Sentry {
  initialise () {
    if (getEnv('VUE_APP_SENTRY_ENABLED') === 'false') {
      return
    }
    const tracePropagationTargets = import.meta.env.VUE_APP_SENTRY_PROPAGATION_TARGETS ?
      import.meta.env.VUE_APP_SENTRY_PROPAGATION_TARGETS
        .split(',')
        .filter((value) => value !== '')
        .map((value) => new RegExp(value, 'g')) : []

    _sentry.init({
      Vue,
      dsn: import.meta.env.VUE_APP_SENTRY_DSN,
      integrations: [
        _sentry.browserTracingIntegration(),
        _sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      environment: import.meta.env.VUE_APP_SENTRY_ENV,
      tracesSampleRate: 1.0,
      tracePropagationTargets,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    })
  }

  addMessageError (message) {
    _sentry.captureMessage(message, 'error')
  }
}

export default new Sentry()