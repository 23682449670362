<template>
  <n-application class="app" :class="wrapperClassName">
    <component :is="layout || 'section'" :class="{ 'app--empty-layout': ! layout }">
      <keep-alive>
        <router-view class="app__view" />
      </keep-alive>
    </component>
    <app-snackbar />
    <app-modal-form
      ref="pincode-prompt"
      v-test="'pincode-prompt'"
      :is-opened.sync="isPinPromptOpened"
      :form="AppPinCodePrompt"
      submit-text="Connexion"
      title="Connexion CPS"
      @submitSuccess="onPinSuccess"
      @submitError="showPinError"
      @cancel="$refs['pincode-prompt'].getForm().reset()"
    />
  </n-application>
</template>

<script>
import AppSnackbar from '@/components/root/snackbar/AppSnackbar.vue'
import AppModalForm from '@/components/ui/modal/AppModalForm.vue'
import AppPinCodePrompt from '@/components/ui/pinCodePrompt/AppPinCodePrompt.vue'

import iCanopeeCardReaderInstance from '@/services/vendors/icanopee/classes/ICanopeeCardReader'
import Tlsi from '@/modules/patient/components/sidebar/tlsi/models/Tlsi'
import Aati from '@/modules/patient/components/sidebar/tlsi/aati/models/Aati'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import Mercure from '@/utils/classes/Mercure'
import { ICANOPEE_CARD_READER_EVENTS } from '@/services/vendors/icanopee/constants/cardReaderEvents.js'
import { CARD_TYPES } from '@/services/card-reader/constants/cardTypes'
import NovaTools from '@/nova-tools/NovaTools'
import { postToAPI } from '@/services/api'

export default {
  name: 'App',
  components: {
    AppSnackbar,
    AppModalForm,
  },
  data () {
    return { AppPinCodePrompt }
  },
  computed: {
    ...mapGetters('auth', ['getCurrentUserTopics', 'isLogged']),
    ...mapGetters('cardReaders', [
      'getCardReaders',
      'getCardReader',
      'getPincodeModalVisible',
      'getHasCard',
      'getPincode',
    ]),
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
    layout () {
      if (this.$route.meta.layoutResolver) {
        const layout = this.$route.meta.layoutResolver()
        return layout === 'empty' ? null : `${this.$route.meta.layoutResolver()}-layout`
      }
      if (this.$route.meta.layout === 'empty') {
        return null
      }
      return (this.$route.meta.layout || 'default') + '-layout'
    },
    wrapperClassName () {
      const routeMeta = this.$route.meta
      return { 'app--no-mobile-bottom-navigation': routeMeta.hideMobileBottomNavigation || (routeMeta.layout === 'empty') }
    },
    isPinPromptOpened: {
      get () {
        return this.getPincodeModalVisible
      },
      set (pincodeModalVisible) {
        this.SET_PINCODE_MODAL_VISIBLE(pincodeModalVisible)
      },
    },
    hasCpxCard () {
      return this.getHasCard(CARD_TYPES.CPX)
    },
    vitaleCardReader () {
      return this.getCardReader(CARD_TYPES.VITALE)
    },
    cpxCardReader () {
      return this.getCardReader(CARD_TYPES.CPX)
    },
  },
  watch: {
    isMobile: {
      immediate: true,
      handler (newVal) {
        this.$store.commit('app/SET_MOBILE', newVal)
      },
    },
    getCurrentUserTopics: {
      immediate: true,
      handler (topics) {
        if (topics && Object.keys(topics).length > 0) {
          // On s'abonne à Mercure
          Object.keys(topics).forEach(topicName => {
            switch (topicName) {
            case 'patients':
              topics[topicName].forEach(topic => {
                Mercure.subscribe(topic, { onSave: (patient, { store }) => store.commit('patient/SET_PATIENT', patient) })
              })
              break
            case 'appointments':
              topics[topicName].forEach(topic => {
                Mercure.subscribe(topic, {
                  onSave: (appointment, { store }) => store.commit('agenda/SET_APPOINTMENT', appointment),
                  onDelete: (appointment, { store }) => store.commit('agenda/DELETE_APPOINTMENT', appointment['@id']),
                })
              })
              break
            case 'notifications':
              topics[topicName].forEach(topic => {
                Mercure.subscribe(topic, {
                  onDelete: (value) => {
                    NovaTools.notify.error(value.message)
                  },
                })
              })
              break
            default:
              throw new Error(`Les topics ${topicName} n'ont pas été gérés`)
            }
          })
        } else {
          // On se désabonne
          Mercure.unsubscribeAll()
        }
      },
    },
    isLogged: {
      immediate: true,
      handler (isLogged) {
        isLogged && this.handlePincodeRequest()
      },
    },
    hasCpxCard: {
      deep: true,
      immediate: true,
      handler (hasCpxCard) {
        this.isLogged && hasCpxCard && this.handlePincodeRequest()
      },
    },
    vitaleCardReader: {
      deep: true,
      async handler (vitaleCardReader, oldVitaleCardReader) {
        if (vitaleCardReader?.s_name !== oldVitaleCardReader?.s_name) {
          await iCanopeeCardReaderInstance.selectCardReader(
            CARD_TYPES.VITALE,
            vitaleCardReader,
            this.getCardReaders.findIndex(reader => reader.s_name === vitaleCardReader.s_name),
          )
        }
      },
    },
    cpxCardReader: {
      deep: true,
      async handler (cpxCardReader, oldCpxCardReader) {
        if (cpxCardReader?.s_name !== oldCpxCardReader?.s_name) {
          await iCanopeeCardReaderInstance.selectCardReader(
            CARD_TYPES.CPX,
            cpxCardReader,
            this.getCardReaders.findIndex(reader => reader.s_name === cpxCardReader.s_name),
          )
        }
      },
    },
  },
  created () {
    this.getAppDatas()
  },
  async mounted () {
    await this.$store.commit('app/SET_MOBILE', this.isMobile)
    iCanopeeCardReaderInstance.addEventListener(ICANOPEE_CARD_READER_EVENTS.STATE_CHANGED, this.onCardReaderStateChanged)
    iCanopeeCardReaderInstance.addEventListener(ICANOPEE_CARD_READER_EVENTS.CONTENT_LOADING, this.onCardReaderLoading)
    iCanopeeCardReaderInstance.addEventListener(ICANOPEE_CARD_READER_EVENTS.CONTENT_CHANGED, this.onCardReaderContentChanged)
    iCanopeeCardReaderInstance.addEventListener(ICANOPEE_CARD_READER_EVENTS.CARD_READER_DEFINED, this.onCardReaderDefined)
    iCanopeeCardReaderInstance.addEventListener(ICANOPEE_CARD_READER_EVENTS.CARD_READERS_LIST_UPDATED, this.onCardReadersListUpdated)
    iCanopeeCardReaderInstance.addEventListener(ICANOPEE_CARD_READER_EVENTS.CARD_READERS_LIST_UPDATING, this.onCardReadersListUpdating)
  },
  destroyed () {
    iCanopeeCardReaderInstance.removeEventListener(ICANOPEE_CARD_READER_EVENTS.STATE_CHANGED, this.onCardReaderStateChanged)
    iCanopeeCardReaderInstance.removeEventListener(ICANOPEE_CARD_READER_EVENTS.CONTENT_LOADING, this.onCardReaderLoading)
    iCanopeeCardReaderInstance.removeEventListener(ICANOPEE_CARD_READER_EVENTS.CONTENT_CHANGED, this.onCardReaderContentChanged)
    iCanopeeCardReaderInstance.removeEventListener(ICANOPEE_CARD_READER_EVENTS.CARD_READER_DEFINED, this.onCardReaderDefined)
    iCanopeeCardReaderInstance.removeEventListener(ICANOPEE_CARD_READER_EVENTS.CARD_READERS_LIST_UPDATED, this.onCardReadersListUpdated)
    iCanopeeCardReaderInstance.removeEventListener(ICANOPEE_CARD_READER_EVENTS.CARD_READERS_LIST_UPDATING, this.onCardReadersListUpdating)
  },
  methods: {
    ...mapActions('app', ['getAppDatas']),
    ...mapMutations('cardReaders', [
      'SET_PINCODE_MODAL_VISIBLE',
      'SET_PINCODE',
      'SET_CARD_READER_CARD_STATE',
      'SET_CARD_CONTENT',
      'SET_IS_LOADING_CARD',
      'SET_CARD_READER',
      'SET_CARD_READERS',
      'SET_CARD_READERS_LIST_UPDATING',
    ]),
    showPinError (error) {
      NovaTools.notify.error(error)
    },
    async onPinSuccess (pincode) {
      this.isPinPromptOpened = false
      NovaTools.notify.success('Connexion CPS réalisée avec succès')
      NovaTools.security.codePin.setCodePin(pincode)
      this.SET_PINCODE(pincode)

      this.checkForAatiReferentials()
    },
    onCardReaderStateChanged ({ cardReaderType, hasCard }) {
      this.SET_CARD_READER_CARD_STATE({
        cardReaderType,
        hasCard,
      })

      if (cardReaderType === CARD_TYPES.CPX && ! hasCard) {
        NovaTools.security.codePin.setCodePin(null)
        this.isPinPromptOpened = false
        NovaTools.notify.warning('Veuillez réintroduire votre CPS.', { timeout: 6000 })
      }
    },
    onCardReaderContentChanged ({ cardReaderType, content }) {
      this.SET_CARD_CONTENT({
        cardReaderType,
        content: content || null,
      })
    },
    onCardReaderLoading ({ cardReaderType, isLoading }) {
      this.SET_IS_LOADING_CARD({
        cardReaderType,
        isLoading,
      })
    },
    onCardReaderDefined ({ cardReaderType, cardReader }) {
      this.SET_CARD_READER({
        cardReaderType,
        cardReader,
      })
    },
    handlePincodeRequest () {
      // Ouverture|Fermeture auto de la modale de saisie de code PIN pour la CPx
      if (! this.getPincode && this.hasCpxCard) {
        this.isPinPromptOpened = true
      } else {
        this.isPinPromptOpened = false
      }
    },
    onCardReadersListUpdated ({ cardReaders }) {
      this.SET_CARD_READERS(cardReaders)
    },
    onCardReadersListUpdating ({ isUpdating }) {
      this.SET_CARD_READERS_LIST_UPDATING(isUpdating)
    },
    async checkForAatiReferentials () {
      try {
        const softwareInformations = await Tlsi.fetchSoftwareInformations()
        if (! softwareInformations.aatiRefSynced) {
          const template = await Aati.getTemplate(softwareInformations, 'GetVersionsReferentiel')
          const motiveTemplate = await Aati.getTemplate(softwareInformations, 'GetListeMotifs')
          const durationTemplate = await Aati.getTemplate(softwareInformations, 'GetDureesIndicatives')

          await postToAPI('/api/tlsi/aati_referential', {
            template,
            motiveTemplate,
            durationTemplate,
          }, { errorHandle: false })
        }
      } catch (error) {}
    },
  },
}
</script>

<style lang="scss" scoped>
.app {
  &--empty-layout {
    height: 100vh;
  }

  &__view {
    width: 100%;
  }

  @include media-md('max') {
    &:not(&--no-mobile-bottom-navigation) {
      ::v-deep {
        .n-application__notifications-center {
          bottom: calc(56px + #{map-get($spacers, 4)});
        }
      }
    }
  }
}
</style>