<template>
  <div class="medical-resource-autocomplete-widget">
    <section ref="widget" />
  </div>
</template>

<script>
import widgetMixin from '@/components/lap/synapse-widgets/mixins/widget'
import autocompleteMixin from '@/components/lap/synapse-widgets/mixins/autocomplete'

export default {
  name: 'MedicalResourceAutocomplete',
  mixins: [widgetMixin, autocompleteMixin],
  data () {
    return {
      SYNAPSE_MEDICAL_RESOURCE_ENTITY_TYPES: {
        BRANDED_DRUGS: 'branded-drugs',
        CLINICAL_DRUGS: 'clinical-drugs',
        MEDICAL_ACCESSORIES: 'medical-accessories',
      },
    }
  },
  async mounted () {
    await this.initWidget({ entityTypes: [this.SYNAPSE_MEDICAL_RESOURCE_ENTITY_TYPES.BRANDED_DRUGS, this.SYNAPSE_MEDICAL_RESOURCE_ENTITY_TYPES.CLINICAL_DRUGS] })

    this.$refs.widget.addEventListener('input', this.handleInput, false)

    this.widget.setCallback('onSelectEntity', (entity) => {
      this.$emit('input', entity)
      this.$emit('update:search-input', null)
      this.$emit('focus-input', true)
    })
  },
}
</script>
<style lang="scss" scoped>
.medical-resource-autocomplete-widget {
  ::v-deep {
    .medical-resources-autocomplete {
      max-width: none;

      fieldset {
        margin: 0 !important;

        label {
          background-color: transparent !important;
          border: none !important;
          outline: none !important;
          height: auto;

          & > div,
          & > button {
            display: none !important;
          }
        }
      }
    }
  }
}
</style>