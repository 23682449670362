import NovaTools from '@/nova-tools/NovaTools'
import EfficienceModalManual from '@/nova-tools/icanopee/efficience/manual/EfficienceModalManual.vue'
import efficience_common from '@/nova-tools/icanopee/efficience/efficience_common'
import { getEnv } from '@/utils/functions/env.js'
export class Efficience {
  #modalId = null
  #query = null
  /**
   * Permet d'envoyer un document au dmp d'un patient au travers d'une iframe d'efficience en mode pilotage
   */
  async sendDocumentByIframe (patient, icanopeeDocument) {
    const modalId = this.openModalManual(patient)
    if(modalId) {
      const relatesTo = JSON.stringify({
        command: 'sendDocumentByIframe',
        iri: icanopeeDocument.iri,
        documentType: icanopeeDocument.documentType,
        modalId,
      })
      await this.sendDocumentManual(icanopeeDocument, relatesTo)
    }
  }
  openModalManual (patient) {
    const { id } = NovaTools.modal.append(EfficienceModalManual, {
      showModal: true,
      url: efficience_common.getEfficienceIframeSearchUrl(patient),
    })
    this.#modalId = id
    return id
  }
  closeModalManual () {
    if (this.#modalId) {
      NovaTools.modal.destroy(this.#modalId)
    }
    this.#modalId = null
    this.#query = null
  }
  async postMessageToEfficienceManual (query) {
    if (query) {
      this.#query = query
      await this.getEfficienceIframe().postMessage(
        JSON.stringify({ remoteCommand: NovaTools.base64.encode(JSON.stringify(query)) }),
        '*',
      )
    }
  }

  getEfficienceIframe () {
    const iframe = document.getElementById('modal-efficience-manual')
    return iframe.contentWindow
  }
  async sendDocumentManual (icanopeeDocument, relatesTo) {
    let contentBase64
    if (! icanopeeDocument.fileContentBase64) {
      contentBase64 = await NovaTools.icanopee.efficience.getBase64Content(icanopeeDocument.contentUrl)
    } else {
      contentBase64 = icanopeeDocument.fileContentBase64
    }
    const params = {
      request: {
        action: 'submitDocument',
        id: relatesTo,
        patient: {
          ins: {
            root: import.meta.env.VUE_APP_ICANOPEE_DMP_CONNECT_JS2_ENVIRONMENT,
            extension: icanopeeDocument.patientIns,
          },
        },
        document: {
          title: icanopeeDocument.title,
          description: icanopeeDocument.title,
          content: contentBase64,
          format: icanopeeDocument.format,
          typeCode: icanopeeDocument.typeCode,
          practice: icanopeeDocument.practice,
        },
        sendInBackground: false,
      },
    }
    if (icanopeeDocument.dmpUniqueId) {
      params.request.document.replacedDocumentUniqueId = icanopeeDocument.dmpUniqueId
    }
    await this.postMessageToEfficienceManual(params)
  }
  async receiveMessageFromEfficienceManual (origin, data) {
    if (origin === getEnv('VUE_APP_ICANOPEE_EFFICIENCE_BASE_URL')) {
      const responseType = data.type
      if (responseType === 'login') {
        await this.postMessageToEfficienceManual(this.#query)
      } else if (responseType === 'remoteNotification') {
        const resultat = JSON.parse(NovaTools.base64.decode(data.data))
        const relatesTo = JSON.parse(resultat.answer.RelatesTo)
        const status = resultat.answer.status
        if (status === 'urn:oasis:names:tc:ebxml-regrep:ResponseStatusType:Failure') {
          await this.onRemoteNotificationFailure(resultat, relatesTo)
        } else if (status === 'urn:oasis:names:tc:ebxml-regrep:ResponseStatusType:Success') {
          await this.onRemoteNotificationSuccess(resultat, relatesTo)
        } else {
          this.closeModalManual()
        }
      } else {
        if (! ['waiting_for_pincode_entry', 'ready_to_receive_commands'].includes(responseType)) {
          this.closeModalManual()
        }
      }
    }
  }
  async onRemoteNotificationFailure (resultat, relatesTo) {
    await NovaTools.dmp.traceDmpSendFailed(relatesTo.iri, relatesTo.documentType)
    if (relatesTo.command === 'sendDocumentByIframe') {
      const errors = resultat.answer.errors
      errors.forEach(item => {
        if (typeof item.displayCode === 'string' || item.displayCode instanceof String) {
          NovaTools.notify.error(item.displayCode)
          this.closeModalManual()
        }
      })
    }
  }
  async onRemoteNotificationSuccess (resultat, relatesTo) {
    const document = resultat.answer.document
    if (document) {
      const { data } = await NovaTools.dmp.traceDmpSend(relatesTo.iri, relatesTo.documentType, {
        dmpUniqueId: document.uniqueId,
        dmpSyncDate: (new Date()).toISOString(),
      }).catch(() => NovaTools.notify.error('Le document n\'a pas pu être envoyé'))

      await NovaTools.icanopee.efficience.updateDocumentInStore(data)

      NovaTools.notify.success('Le fichier a été ajouté avec succès')
      this.closeModalManual()
    }
  }
}
export default new Efficience()