<template>
  <section>
    <n-list
      v-if="localKeywords.length"
      :items="localKeywords"
      inline
      empty-text="Aucun mot-clef"
    >
      <template #item="{ item, index }">
        <display-patient-keyword :keyword="item" />
        <app-button
          v-if="index === localKeywords.length-1 && canManage"
          v-test="'keyword-manage-button'"
          icon="edit"
          x-small
          tooltip="Gérer les mots-clefs"
          class="ml-2"
          @click.stop="showKeywordForm"
        />
      </template>
    </n-list>

    <app-button
      v-if="!localKeywords.length && canManage"
      v-test="'keyword-manage-button'"
      text
      color="primary"
      small
      class="mx-n3"
      @click.stop="showKeywordForm"
    >
      <app-icon icon="keyword" class="mr-2" /> Gérer les mots-clefs
    </app-button>

    <app-modal-form
      v-if="canManage"
      v-model="editingPatientKeywords"
      v-test="'keyword-modal'"
      title="Gérer les mots-clefs"
      :form="PatientKeywordsLinkForm"
      :patient="patient"
      scrollable
      @cancel="editingPatientKeywords = null"
      @submitSuccess="changeKeywords"
    />
  </section>
</template>

<script>
import { mapMutations } from 'vuex'
import { LocalCopyMixin } from '@novalys/mixins'

import AppModalForm from '@/components/ui/modal/AppModalForm.vue'
import AppButton from '@/components/ui/buttons/AppButton.vue'
import AppIcon from '@/components/ui/icon/AppIcon.vue'

import DisplayPatientKeyword from '@/modules/patient/components/keywords/components/DisplayPatientKeyword.vue'
import PatientKeywordsLinkForm from '@/modules/patient/components/keywords/components/PatientKeywordsLinkForm.vue'

import Patient from '@/modules/patient/models/Patient'
import PatientKeyword from '@/modules/patient/models/PatientKeyword'

export default {
  name: 'PatientKeywordsList',
  components: {
    AppModalForm,
    AppButton,
    AppIcon,
    DisplayPatientKeyword,
  },
  mixins: [LocalCopyMixin({
    propertyName: 'keywords',
    copyPropertyName: 'localKeywords',
  })],
  props: {
    /**
     * Le patient pour lequel les mots-clefs seront affichés
     */
    patient: {
      type: Patient,
      required: true,
    },
    /**
     * Les mots clefs à afficher
     */
    keywords: {
      type: Array,
      required: true,
      validator: keywords => keywords.every(keyword => keyword instanceof PatientKeyword),
    },
    /**
     * Autorise ou non la gestion des mots-clefs (ajout / suppression)
     */
    canManage: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      PatientKeywordsLinkForm,
      editingPatientKeywords: null,
    }
  },
  methods: {
    ...mapMutations('patient', { setPatient: 'SET_PATIENT' }),
    showKeywordForm () {
      this.editingPatientKeywords = this.patient
    },
    changeKeywords (keywords) {
      this.setPatient({
        ...this.patient,
        keywords,
      })
      this.editingPatientKeywords = null
    },
  },
}
</script>

<style lang="scss" scoped>
  .keywords-list {
    display: flex;
    align-content: center;
    padding-left: 0;
    li {
      list-style: none;
      margin-right: 5px;
    }
  }
</style>