<template>
  <app-form :submit-method="saveDrugAllergy">
    <drug-allergy-autocomplete
      v-model="selectedDrugAllergy"
      class="mb-2"
      :rules="{ required: true, excluded: forbiddenDrugAllergiesLabel }"
      data-test="drug-allergy-form-label-field"
    />
    <app-textarea-field
      v-model="localDrugAllergy.note"
      label="Commentaire (optionnel)"
      hide-errors="auto"
      data-test="drug-allergy-form-note-field"
      rows="1"
      auto-grow
    />
  </app-form>
</template>

<script>
import AppForm from '@/components/ui/form/AppForm.vue'
import DrugAllergyAutocomplete from '@/components/lap/DrugAllergyAutocomplete.vue'
import AppTextareaField from '@/components/ui/form/AppTextareaField.vue'

import { mapActions, mapGetters } from 'vuex'

import Patient from '@/modules/patient/models/Patient'
import PatientDrugAllergy from '@/modules/patient/models/riskFactors/PatientDrugAllergy'
import NovaTools from '@/nova-tools/NovaTools'

export default {
  name: 'DrugAllergyForm',
  components: {
    AppForm,
    DrugAllergyAutocomplete,
    AppTextareaField,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      localDrugAllergy: null,
      selectedDrugAllergy: null,
    }
  },
  computed: {
    ...mapGetters('patient', ['getPatientDrugAllergies']),
    isEditing () {
      return !! this.localDrugAllergy?.['@id']
    },
    forbiddenDrugAllergiesLabel () {
      const patientDrugAllergies = this.getPatientDrugAllergies(this.patient.getUuid())
      if (this.isEditing) {
        return patientDrugAllergies.filter(allergy => allergy['@id'] !== this.localDrugAllergy['@id']).map(drugAllergy => drugAllergy.label)
      }
      return patientDrugAllergies.map(drugAllergy => drugAllergy.label)
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler (value) {
        this.localDrugAllergy = new PatientDrugAllergy(value ? {
          ...value,
          patient: this.patient['@id'],
        } : { patient: this.patient['@id'] })
        this.selectedDrugAllergy = { label: value?.label }
      },
    },
    'selectedDrugAllergy.label' (newAllergyLabel) {
      this.localDrugAllergy.label = newAllergyLabel
    },
  },
  methods: {
    ...mapActions('patient', ['insertPatientDrugAllergy', 'updatePatientDrugAllergy']),
    async saveDrugAllergy () {
      let message
      if (this.isEditing) {
        await this.updatePatientDrugAllergy(this.localDrugAllergy)
        message = 'L\'allergie a été modifiée avec succès'
      } else {
        await this.insertPatientDrugAllergy(this.localDrugAllergy)
        message = 'L\'allergie a été ajoutée avec succès'
      }
      NovaTools.notify.success(message)
    },
  },
}
</script>