<template>
  <app-panel :title="panelTitle">
    <app-skeleton-loader
      v-if="loading"
      type="text"
      width="200"
      class="mb-5"
    />
    <dashboard-events-timeline data-test="dashboard-events-timeline" :events="events" :loading="loading" />
    <span v-if="!events.length && ! loading" class="secondary--text" data-test="dashboard-events-no-data">
      Vous pouvez vous rendre sur l'agenda afin d'en ajouter.
    </span>
  </app-panel>
</template>
<script>
import AppPanel from '@/components/ui/panel/AppPanel.vue'
import DashboardEventsTimeline from './timeline/DashboardEventsTimeline.vue'
import AppSkeletonLoader from '@/components/ui/loaders/AppSkeletonLoader'

import { pluralize } from '@/utils/functions/words'

export default {
  name: 'DashboardEventsPanel',
  components: {
    AppPanel,
    DashboardEventsTimeline,
    AppSkeletonLoader,
  },
  props: {
    events: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    panelTitle () {
      return this.loading
        ? ''
        : `${this.events.length || 'Aucun'} ${pluralize('évènement', this.events.length)}`
    },
  },
}
</script>