import Vue from 'vue'
import { getFromAPI, putToAPI } from '@/services/api.js'
import { getUUIDFromIRI } from '@/utils/functions/getUUIDFromIRI'

import PatientFemaleMedicalDataset from '../components/patientFile/femaleMedicalDataset/models/PatientFemaleMedicalDataset'

export const state = () => ({ femaleMedicalDataset: {} })

export const getters = {
  getFemaleMedicalDataset: state => (patientUuid) => {
    return state.femaleMedicalDataset[patientUuid]
  },
}

export const mutations = {
  SET_FEMALE_MEDICAL_DATASET (state, { patientUuid, femaleMedicalDataset }) {
    Vue.set(state.femaleMedicalDataset, patientUuid, femaleMedicalDataset)
  },
}

export const actions = {
  async fetchFemaleMedicalDataset ({ commit }, patient) {
    const { data } = await getFromAPI(patient.femaleMedicalDataset)
    commit('SET_FEMALE_MEDICAL_DATASET', {
      patientUuid: getUUIDFromIRI(data.patient),
      femaleMedicalDataset: new PatientFemaleMedicalDataset(data),
    })
  },
  async updateFemaleMedicalDataset ({ commit }, { femaleMedicalDataset }) {
    const { data } = await putToAPI(femaleMedicalDataset.getIri(), { data: femaleMedicalDataset })
    commit('SET_FEMALE_MEDICAL_DATASET', {
      patientUuid: getUUIDFromIRI(data.patient),
      femaleMedicalDataset: new PatientFemaleMedicalDataset(data),
    })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}