<template>
  <div>
    <app-panel title="Rendez-vous" class="mb-last-0" :actions="createAppointmentActions">
      <app-tabs bottom-lined grow>
        <app-tabs-item v-test="'future-appointments-tab'" label="À venir" :badge-content="futureAppointmentsCount">
          <appointments-tab
            :patient="patient"
            appointment-category="future"
            @update-count="futureAppointmentsCount = $event"
          />
        </app-tabs-item>
        <app-tabs-item
          v-test="'past-appointments-tab'"
          label="Passés"
          :badge-content="pastAppointmentsCount"
          always-mounted
        >
          <appointments-tab
            :patient="patient"
            appointment-category="past"
            @update-count="pastAppointmentsCount = $event"
          />
        </app-tabs-item>
      </app-tabs>
    </app-panel>
    <free-appointment-slot-drawer
      v-model="editingFreeAppointmentSlot"
      :patient="patient"
      @cancel="onFreeAppointmentSlotCanceled"
    />
  </div>
</template>

<script>
import AppPanel from '@/components/ui/panel/AppPanel'
import AppointmentsTab from '@/modules/patient/modules/patientFile/modules/overview/components/appointmentsPanel/AppointmentsTab.vue'
import FreeAppointmentSlotDrawer from '@/modules/patient/modules/patientFile/modules/overview/components/appointmentsPanel/FreeAppointmentSlotDrawer.vue'

import Patient from '@/modules/patient/models/Patient'
import FreeAppointmentSlot from '@/modules/patient/models/medicalHistory/FreeAppointmentSlot'

import NovaTools from '@/nova-tools/NovaTools'

export default {
  name: 'AppointmentsPanel',
  components: {
    AppPanel,
    AppointmentsTab,
    FreeAppointmentSlotDrawer,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      createAppointmentActions: {
        add: {
          callback: this.openFreeAppointmentSlotDrawer,
          icon: 'schedule-plus',
          text: 'Planifier un rendez-vous',
        },
      },
      futureAppointmentsCount: 0,
      pastAppointmentsCount: 0,
      editingFreeAppointmentSlot: null,
    }
  },
  methods: {
    openFreeAppointmentSlotDrawer () {
      this.editingFreeAppointmentSlot = new FreeAppointmentSlot({ startDateTime: NovaTools.dates.format(new Date(), 'yyyy-MM-dd') })
    },
    onFreeAppointmentSlotCanceled () {
      this.editingFreeAppointmentSlot = null
    },
  },
}
</script>