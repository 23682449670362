<template>
  <div class="administrative-resume">
    <div class="d-flex justify-space-between">
      <patient-details
        v-test="'patient-details'"
        :patient="patient"
        show-birth-informations
        can-manage-keywords
        align-content-top
        :show-anonymized-identifier="false"
      >
        <template #civil-state>
          <div v-test="'patient-identity'">
            <p class="administrative-resume__patient-civil-state-title">
              Identité utilisée
            </p>
            <p>{{ patient.getCivilState() }}</p>
          </div>
        </template>
      </patient-details>
      <patient-sidebar-actions :patient="patient" class="administrative-resume__actions" />
    </div>
    <n-divider :spacing="1" />
    <administrative-listing :patient="patient" />
    <n-expandable v-test="'address-contact-points-wrapper'" title="Coordonnées" :expanded="hasAddressOrContactPoints">
      <div v-if="hasAddressOrContactPoints">
        <app-person-address v-test="'address'" class="mb-2" :address="patient.address" />
        <app-contact-point-list v-if="hasContactPoints" :contact-points="contactPoints" />
      </div>

      <p v-else class="secondary--text">
        Aucune coordonnée pour ce patient
      </p>
    </n-expandable>
  </div>
</template>

<script>
import AppContactPointList from '@/components/ui/contactPointList/AppContactPointList.vue'
import AppPersonAddress from '@/components/ui/personAddress/AppPersonAddress.vue'
import AdministrativeListing from '@/modules/patient/components/patientFile/administrativeResume/AdministrativeListing.vue'
import PatientDetails from '@/modules/patient/components/PatientDetails.vue'
import PatientSidebarActions from '@/modules/patient/components/sidebar/PatientSidebarActions.vue'

import { FAMILY_SITUATIONS } from '@/constants'

import Patient from '@/modules/patient/models/Patient'

export default {
  name: 'AdministrativeResume',
  components: {
    AppContactPointList,
    AppPersonAddress,
    PatientDetails,
    PatientSidebarActions,
    AdministrativeListing,
  },
  props: {
    patient: {
      type: Patient,
      default: null,
    },
  },
  computed: {
    familySituation () {
      const { familySituation } = this.patient
      return familySituation
        ? FAMILY_SITUATIONS[familySituation.toUpperCase()].label
        : null
    },
    contactPoints () {
      return this.patient.contactPoints.filter(contactPoint => !! contactPoint.value)
    },
    hasContactPoints () {
      return this.contactPoints.length > 0
    },
    hasAddressOrContactPoints () {
      return this.patient.address !== null || this.hasContactPoints
    },
  },
}
</script>

<style lang="scss" scoped>
.administrative-resume {
  display: flex;
  flex-direction: column;
  gap: map-get($spacers, 2);

  &__actions {
    margin: -10px -10px auto map-get($spacers, 2);
  }

  &__patient-civil-state-title {
    font-size: 12px;
    font-weight: 400;
    color: var(--v-secondary-base);
  }
}
</style>