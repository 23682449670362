import patientsMeasuresStore from './measures'
import patientRiskFactorsStore from './riskFactors'
import patientMedicalDatasetStore from './medicalDataset'
import femaleMedicalDatasetStore from './femaleMedicalDataset'
import patientsConsultationsStore from './consultations'
import patientsNotesStore from './notes'
import ongoingMedications from '@/modules/patient/store/ongoingMedications'
import patientDocumentDmpSyncsStore from './documentDmpSyncs'

import Vue from 'vue'

import { getFromAPI, patchToAPI } from '@/services/api.js'
import { mapStoreResetter } from '@/utils/functions/store'

import Patient from '@/modules/patient/models/Patient'

export const state = () => ({
  settings: {},
  settingsPath: null,
  patients: {},
  isFetchingPatients: false,
  filteredPatients: {},
  patientCount: 0,
  filteredPatientCount: 0,
  ...patientsMeasuresStore.state(),
  ...patientRiskFactorsStore.state(),
  ...patientMedicalDatasetStore.state(),
  ...femaleMedicalDatasetStore.state(),
  ...patientsConsultationsStore.state(),
  ...patientsNotesStore.state(),
  ...ongoingMedications.state(),
  ...patientDocumentDmpSyncsStore.state(),
})

export const getters = {
  getSettings: state => state.settings,
  getPatients: state => Object.values(state.patients).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
  isFetchingPatients: state => state.isFetchingPatients,
  getFilteredPatients: state => Object.values(state.filteredPatients),
  getPatientByUUID: state => uuid => state.patients[`/api/patients/${uuid}`],
  getPatientByIRI: state => iri => state.patients[iri],
  ...patientsMeasuresStore.getters,
  ...patientRiskFactorsStore.getters,
  ...patientMedicalDatasetStore.getters,
  ...femaleMedicalDatasetStore.getters,
  ...patientsConsultationsStore.getters,
  ...patientsNotesStore.getters,
  ...ongoingMedications.getters,
  ...patientDocumentDmpSyncsStore.getters,
}

export const mutations = {
  ...mapStoreResetter(state),
  SET_SETTINGS (state, settings) {
    state.settings = settings
  },
  SET_SETTINGS_PATH (state, settingsPath) {
    state.settingsPath = settingsPath
  },
  SET_PATIENT (state, patient) {
    Vue.set(state.patients, patient['@id'], new Patient(patient))
  },
  SET_IS_FETCHING_PATIENTS (state, isFetchingPatients) {
    state.isFetchingPatients = isFetchingPatients
  },
  SET_CURRENT_PATIENT (state, patient) {
    Vue.set(state, 'currentPatient', new Patient(patient))
  },
  SET_FILTERED_PATIENT (state, patient) {
    Vue.set(state.filteredPatients, patient['@id'], new Patient(patient))
  },
  SET_ALL_FILTERED_PATIENTS (state, patients) {
    const uuidsObject = patients.reduce((acc, patient) => ({
      ...acc,
      [patient['@id']]: new Patient(patient),
    }), {})
    Vue.set(state, 'filteredPatients', uuidsObject)
  },
  SET_PATIENT_COUNT (state, patientCount) {
    state.patientCount = patientCount
  },
  SET_FILTERED_PATIENT_COUNT (state, filteredPatientCount) {
    state.filteredPatientCount = filteredPatientCount
  },
  ...patientsMeasuresStore.mutations,
  ...patientRiskFactorsStore.mutations,
  ...patientMedicalDatasetStore.mutations,
  ...femaleMedicalDatasetStore.mutations,
  ...patientsConsultationsStore.mutations,
  ...patientsNotesStore.mutations,
  ...ongoingMedications.mutations,
  ...patientDocumentDmpSyncsStore.mutations,
}

export const actions = {
  async fetchSettings (context) {
    const { data } = await getFromAPI('/api/user_settings', { name: 'patient_ins_identity' })
    context.commit('SET_SETTINGS_PATH', data['hydra:member'][0]['@id'])
    context.commit('SET_SETTINGS', data['hydra:member'][0].value)
  },
  async updateSettings ({ state, commit }, settings) {
    const { data } = await patchToAPI(state.settingsPath, { data: { value: settings } })
    commit('SET_SETTINGS', data.value)
  },
  async fetchOnePatient ({ commit }, patientUUID) {
    const { data } = await getFromAPI(`/api/patients/${patientUUID}`)
    commit('SET_PATIENT', data)
  },
  async fetchPatients ({ commit }) {
    commit('SET_IS_FETCHING_PATIENTS', true)
    try {
      const { data } = await getFromAPI('/api/patients', {
        pagination: true,
        itemsPerPage: 30,
      })
      data['hydra:member'].forEach(patient => commit('SET_PATIENT', patient))
      commit('SET_PATIENT_COUNT', data['hydra:totalItems'])
    } catch (e) {}
    commit('SET_IS_FETCHING_PATIENTS', false)
  },
  async searchPatient ({ commit, getters }) {
    commit('SET_IS_FETCHING_PATIENTS', true)
    try {
      const { data } = await getFromAPI('/api/patients', getters.getFiltersValue)
      commit('SET_ALL_FILTERED_PATIENTS', data['hydra:member'])
      commit('SET_FILTERED_PATIENT_COUNT', data['hydra:totalItems'])
    } catch (e) {}
    commit('SET_IS_FETCHING_PATIENTS', false)
  },
  async insertPatient ({ commit }, patient) {
    const createdPatient = await Patient.insert(patient)
    commit('SET_PATIENT', createdPatient)
    return createdPatient
  },
  async updatePatient ({ commit }, patient) {
    const updatedPatient = await Patient.update(patient)
    commit('SET_PATIENT', updatedPatient)
    return updatedPatient
  },

  ...patientsMeasuresStore.actions,
  ...patientRiskFactorsStore.actions,
  ...patientMedicalDatasetStore.actions,
  ...femaleMedicalDatasetStore.actions,
  ...patientsConsultationsStore.actions,
  ...patientsNotesStore.actions,
  ...ongoingMedications.actions,
  ...patientDocumentDmpSyncsStore.actions,
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}